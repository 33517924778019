import React from "react";
import { graphql, PageProps } from "gatsby";

import { Container, PageWidth } from "@util/standard";
import { Query } from "@graphql-types";
import { Header } from "@shared";
import { assets, colors, mediaQuery } from "@util/constants";
import styled from "styled-components";
import { BlocksContent, Link } from "@global";

interface Props extends PageProps {
  data: Query;
}
// markup
const NotFoundPage = ({ data }: Props) => {
  const { sanityHeader, sanityPageNotFound } = data;

  return (
    <div>
      <Header data={sanityHeader} />
      <StyledContainer backgroundColor="navy" padding="100px">
        <StyledImage src={assets.notFound} />
        <div className="text-wrapper">
          {sanityPageNotFound && sanityPageNotFound.heroText && (
            <BlocksContent data={sanityPageNotFound.heroText} />
          )}
        </div>
      </StyledContainer>
      <PageWidth>
        <Container
          backgroundColor="white"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {sanityPageNotFound && sanityPageNotFound.bodyText && (
            <Container margin="30px auto">
              <BlocksContent data={sanityPageNotFound.bodyText} />
            </Container>
          )}
          {sanityPageNotFound?.pageLink && (
            <Link {...sanityPageNotFound.pageLink} className="button-base" />
          )}
        </Container>
      </PageWidth>
    </div>
  );
};

export default NotFoundPage;

const StyledImage = styled.img`
  width: 60%;
  margin: 100px auto 50px auto;

  ${mediaQuery.tabletDown} {
    margin: auto;
  }
`;

const StyledContainer = styled(Container)`
  flex-direction: column;
  text-align: center;
  .text-wrapper {
    width: 60%;
    margin: auto;
    color: ${colors.cyan};
  }
  min-height: 70vh;
  ${mediaQuery.tabletDown} {
    height: 60vh;
    padding: 50px;
  }
  ${mediaQuery.mobileDown} {
    height: 40vh;
    padding: 80px;
  }
`;

export const query = graphql`
  query notFound {
    sanityHeader {
      ...sanityHeader
    }
    sanityPageNotFound {
      pageLink {
        ...sanityLink
      }
      heroText {
        _key
        _type
        _rawBlockContent
      }
      bodyText {
        _key
        _type
        _rawBlockContent
      }
    }
  }
`;
